import React, { useState } from 'react';
import { notify, url } from "../../constants/Constants";
import { Link } from "react-router-dom";
import JovanGroup from "../../constants/BlogApi";
import defaultImage from "../../assets/logo.png"

const UserItem = ({ data, reload }) => {

    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);

    function deleteuser(id) {

        setLoader(true);
        JovanGroup.Users.delete(id).then(res => {
            if (res.data) {
                notify(true, "Suppression effectuée ")
                reload();
                setLoader(false);
            }
        }).catch(err => {
            notify(false, "Suppression non effectuée ");
            setLoader(false);
        });

    }

    function UpdateUser(e) {

        e.preventDefault();
        setLoader2(true);
        let formadata = new FormData(e.target);
        JovanGroup.Users.update(data.id, formadata).then(res => {
            if (res.data) {
                notify(true, "Modification effectuée ")
                reload();
                setLoader2(false);
            }
        }).catch(err => {
            notify(false, "Modification non effectuée ");
            setLoader2(false);
        });

    }

    return (
        <tr>

            <td> <i className="fas fa-user me-2 jovan-color"/> {data.username}</td>
            <td>
                {
                    "roles" in data && data.roles
                }
            </td>


            <td className="d-inline-flex ">
                <button className="btn btn-outline-danger me-3 " onClick={() => deleteuser(data.id)}>
                    {
                        loader ?
                            <div className="spinner-border spinner-border-sm text-danger" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <i className="fas fa-trash-alt"></i>
                    }
                </button>

                <button className="btn btn-outline-primary ml-2" data-bs-toggle="modal" data-bs-target={"#Update" + data.id}>
                    <i className="fas fa-pen"/>
                </button>
            </td>


            <div className="modal" id={"Update"+data.id} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">

                    <div className="modal-content px-4">
                        <div className="modal-header">
                            <h5 className="modal-title">Modifier un {data ? data.username : null    }</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>

                        <form onSubmit={UpdateUser}>
                            <div className="modal-body">

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="username">Nom d'utilisateur</label>
                                    <input type="text" className="form-control" defaultValue={data.username} name="username" placeholder="username " />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="password">Mot de passe </label>
                                    <input className="form-control" name="password" type="password" />
                                </div>


                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                                <button type="submit" className="btn btn-primary">
                                    {
                                        loader2 ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Modifier"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </tr>
    );
}

export default UserItem;
