import React, {useState,useEffect} from 'react';
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar";
import JovanGroup from "../../constants/BlogApi";
import {notify} from "../../constants/Constants";
import CategoryItem from "../../components/CategoryItem";

const Categorys = () => {

    const [Categorys, setCategorys] = useState([]);
    const [add, setAdd] = useState(false);
    const [loader, setLoader] = useState(false);
    const roles = ["ROLE_EDITOR"];
    let role = [];

    function Reload() {
        getCategorys();
    }

    function addCat(event)  {

        setAdd(true);

        event.preventDefault();
        let formData = new FormData(event.target);
        JovanGroup.Category.add(formData).then(res => {
            if (res.data) {
                setAdd(false);
                notify(true, "Ajout de la Catégorie effectué");

                Reload();
            }
        }).catch(err => {
            setAdd(false);
            notify(true, null, "Echec de l'ajout de la Catégorie");
        });
    }

    function getCategorys() {
        setLoader(true)
        JovanGroup.Category.get().then(res => {
            if (res.data) {
                setLoader(false)
                setCategorys(res.data);
            }
        }).catch()
    };

    useEffect(() => {
        role = sessionStorage.getItem("roles");
        getCategorys();
    }, []);

    return (
        <>
            <Navbar />

            <div className="container-fluid">
                <div className="row">

                    <Sidebar/>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Gestion des Editeurs</h1>

                            <button className="btn btn-sm btn-outline-primary " data-bs-target="#modalAdd" data-bs-toggle="modal">
                                <i className="fas fa-user-plus me-2" />
                                Ajouter une catégorie
                            </button>

                        </div>

                        {
                            loader ?
                                <div className="text-center w-100">
                                    <div className="spinner-grow jo" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> :
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Nom </th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            Categorys && Categorys.length !== 0 ?
                                            Categorys.map((el)=>{
                                                return <CategoryItem data={el} reload={Reload} />
                                            }) :
                                                <div className="col-12 col-md-12">
                                                    <p>Aucune catégorie n'a été créé.</p>
                                                </div>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                        }


                    </main>

                </div>

            </div>

            <div className="modal" id="modalAdd" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">

                    <div className="modal-content px-4">
                        <div className="modal-header">
                            <h5 className="modal-title">Ajouter une Catégorie</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>

                        <form onSubmit={addCat}>

                            <div className="modal-body">

                                <div className="form-group">
                                    <label className="form-label" htmlFor="name">Titre de la Catégorie</label>
                                    <input type="text" className="form-control" name="name" placeholder="nom ..." />
                                </div>



                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-log" data-dismiss="modal">Annuler</button>
                                <button type="submit" className="btn btn-primary btn-log">
                                    {
                                        add ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only" >Loading...</span>
                                        </div> : "Ajouter "
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Categorys;