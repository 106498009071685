import axios from "axios";
import { url } from "./Constants";

const Axios = axios.create({
    baseURL: url,
    headers: {
        Authorization: sessionStorage.getItem("token")
    }
});

const Article = {

    get() {
        return Axios.get("/news");
    },
    add(data) {
        return Axios.post("/news", data, {})
    },
    detail(id) {
        return Axios.get("/news/" + id);
    },
    update(id, data) {
        return Axios.post("/news/" + id + "?_method=PUT", data, {});
    },
    addContent(id, data) {
        return Axios.post("/news/" + id + "?_method=PUT", data, {})
    },
    gals(data) {
        return Axios.post("/galerys", data);
    },
    deletegalerys(id) {
        return Axios.post("/galerys/" + id + "?_method=DELETE", []);
    },
    deletenews(id) {
        return Axios.post("/news/" + id + "?_method=DELETE", []);
    }

}

const Category = {

    get() {
        return Axios.get("/categorys");
    },
    add(data) {
        return Axios.post("/categorys", data, {})
    },
    detail(id) {
        return Axios.get("/categorys/" + id);
    },
    updateCat(id, data) {
        return Axios.post("/categorys/" + id + "?_method=PUT", data, {});
    },
    deletecategory(id) {
        return Axios.post("/categorys/" + id + "?_method=DELETE", []);
    }

}

const Links = {
    get() {
        return Axios.get("/links")
    },
    add(data) {
        return Axios.post("/links", data, {})
    },
};

const Videos = {
    get() {
        return Axios.get("/videos")
    },
    add(data) {
        return Axios.post("/videos", data, {})
    },
};

const Users = {
    async login(data) {
        return await Axios.post("/login", data).then(responses => {
            return responses;
        }
        ).catch(error => {
            return Object.assign({}, error).response;
        });
    },

    get() {
        return Axios.get("/users")
    },
    add(data) {
        return Axios.post("/users", data, {})
    },
    update(id, data) {
        return Axios.post("/users/" + id + "?_method=PUT", data, {});
    },
    detail(id) {
        return Axios.get("/users/", id, {})
    },
    delete(id) {
        return Axios.post("/users/" + id + "?_method=DELETE", []);
    }
};


const JovanGroup = { Article, Links, Category, Users, Videos };

export default JovanGroup;
