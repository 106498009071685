import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import React from "react";
import './App.css';
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Users from "./Pages/Users";
import Categorys from "./Pages/Categorys";
import Articles from "./Pages/Articles";
import UpArticles from "./Pages/UpdateArticles";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route path="/accueil" component={Home}/>
                <Route path="/editeurs" component={Users}/>
                <Route path="/categories" component={Categorys}/>
                <Route path="/articles" component={Articles}/>
                <Route path="/update/:id" component={UpArticles}/>
            </Switch>
        </Router>
    );
}

export default App;
