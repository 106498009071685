import React from 'react';
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar";

const Home = () => {
    return (
        <>
            <Navbar />

            <div className="container-fluid">
                <div className="row">

                    <Sidebar/>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center border-bottom "
                             style={{height:"calc(100vh - 68px)"}}>
                            <h1 className="h2">Bienvenue sur votre Gestionnaire</h1>

                        </div>
                    </main>

                </div>

            </div>

        </>
    );
};

export default Home;