import React, {useState,useEffect} from 'react';
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar";
import JovanGroup from "../../constants/BlogApi";
import {notify} from "../../constants/Constants";
import $ from "jquery";
import UserItem from "../../components/UserItem";

const Users = () => {

    const [Users, setUsers] = useState([]);
    const [add, setAdd] = useState(false);
    const [loader, setLoader] = useState(false);
    const roles = ["ROLE_EDITOR"];
    let role = [];


    function Reload() {
        getUsers();
    }

    function addUser(event) {

        setAdd(true);
        event.preventDefault();
        let formData = new FormData(event.target);
        formData.append("roles", JSON.stringify(roles));

        JovanGroup.Users.add(formData).then(res => {

            if (res.data) {

                setAdd(false);
                notify(true, "Ajout de l'utilisateur effectué");
                Reload();

            }
        }).catch(err => {

            setAdd(false);
            notify(true, null, "Echec de l'ajout de l'utilisateur");

        });
    }

    function getUsers() {
        setLoader(true)
        JovanGroup.Users.get().then(res => {
            if (res.data) {
                setLoader(false)
                setUsers(res.data);
            }
        }).catch()
    };

    useEffect(() => {

        role = sessionStorage.getItem("roles");



        getUsers();
    }, []);

    return (
        <>
            <Navbar />

            <div className="container-fluid">
                <div className="row">

                    <Sidebar/>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div
                            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Gestion des Editeurs</h1>

                                <button className="btn btn-sm btn-outline-primary mb-2 mb-md-0" data-bs-target="#modalAdd" data-bs-toggle="modal">
                                    <i className="fas fa-user-plus me-2" />
                                    Ajouter un Editeur
                                </button>

                        </div>

                        {
                            loader ?
                                <div className="text-center w-100">
                                    <div className="spinner-grow jo" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> :
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Nom d'utilisateur</th>
                                            <th scope="col">Roles</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            Users && Users.length !== 0 &&
                                            Users.map((el)=>{
                                                return <UserItem data={el} reload={Reload} />
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                        }


                    </main>

                </div>

            </div>

            <div className="modal" id="modalAdd" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal" role="document">

                    <div className="modal-content px-4">
                        <div className="modal-header">
                            <h5 className="modal-title">Ajouter un utilisateur</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>

                        </div>

                        <form onSubmit={addUser}>
                            <div className="modal-body">

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="username">Nom d'utilisateur</label>
                                    <input type="text" className="form-control" name="username" placeholder="username " />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="password">Mot de passe </label>
                                    <input className="form-control" name="password" type="password" />
                                </div>


                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-log btn-secondary" data-dismiss="modal">Annuler</button>
                                <button type="submit" className="btn btn-log btn-primary">
                                    {
                                        add ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Ajouter"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Users;