import React, { useState, useEffect } from 'react';
import logo from "../../assets/logo.png";
import {Link} from "react-router-dom";

const Navbar = () => {

    const [state, setState] = useState();


    const reload = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        window.location.reload();
    };

    function logout (){
        sessionStorage.clear();
        window.location.replace("/")
    }

    useEffect(() => {
        setState(sessionStorage.getItem("userId"));
    }, []);

    return (

        <header className="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0 shadow-sm">
            <a className="col-md-3 col-lg-2 me-0 text-center py-1" href="/accueil">
                <img src={logo} className="img-fluid" width="120" alt=""/>
            </a>

            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
            </button>

            <ul className="navbar-nav mx-auto mx-md-0 px-3">
                <li className="nav-item ">
                    <span className="nav-link" to="/" onClick={logout}>
                        <span className="btn btn-danger">
                            <i className="fas fa-sign-in-alt pe-2" />
                            Déconnexion
                        </span>
                    </span>
                </li>
            </ul>
        </header>

    );

};

export default Navbar;
