import React from 'react';
import {Link} from "react-router-dom";

const Sidebar = () => {

    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-4">
                <h4 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Gestion du Blog</span>
                </h4>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link  className="nav-link "  to="/articles">
                            <i className="fas fa-file me-2 jovan-color" />
                            Gestion des articles
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/categories">
                            <i className="fas fa-tags me-2 jovan-color" />
                            Gestion des catégories
                        </Link>
                    </li>
                    {
                        sessionStorage.getItem("roles") === "ROLE_USER" &&
                        <li className="nav-item">
                            <Link className="nav-link" to="/editeurs">
                                <i className="fas fa-user-edit me-2 jovan-color" />
                                Gestion des éditeurs
                            </Link>
                        </li>
                    }


                </ul>

            </div>
        </nav>
    );
};

export default Sidebar;