import React, {useState} from 'react';
import logo from "../../assets/logo.png"
import JovanGroup from "../../constants/BlogApi";
import {notify} from "../../constants/Constants";

const Login = () => {

    const [loading,setLoading] = useState(false);


    const userLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        let response = await JovanGroup.Users.login(new FormData(e.target));
        // sessionStorage.setItem('userId', 1);
        // sessionStorage.setItem('token', "response.data.token");
        // window.location.replace('/');
        setLoading(false);
        if (response.status === 200){
            sessionStorage.setItem('userId', response.data.id);
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('roles', response.data.roles);
            window.location.replace('/accueil')
        }else if(response.status === 400){
            notify(false,'error',response.data.errors)
        }
    };


    return (
        <div className="log-container" >
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 col-md-5 mx-auto ">

                        <div className="card card-signin  w-100 p-1 p-md-5">
                            <div className="card-header bg-transparent border-none pb-5 text-center" >
                                <img src={logo} width={255} alt=""/>
                            </div>
                            <div className="card-body text-center">
                                <h5 className="card-title my-4 text-center">Connexion </h5>
                                <form onSubmit={userLogin}>
                                    <div className="form-label-group mb-4">
                                        <input type="text" name="username" className="form-control" placeholder="username" required />
                                    </div>

                                    <div className="form-label-group">
                                        <input type="password" name="password" className="form-control" placeholder="Password" required />
                                    </div>


                                    <button type="submit" className="btn my-4 btn-lg btn-primary btn-block text-uppercase btn-log" >

                                        { loading ?
                                            <span className="spinner-border spinner-border-sm" role="status"/> :
                                            <> <i className="fas fa-sign-in-alt mr-2"/> <span>Se connecter</span> </> }
                                    </button>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};

export default Login;