import React, {useState,useEffect} from 'react';
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar";
import JovanGroup from "../../constants/BlogApi";
import {notify, url} from "../../constants/Constants";
import {useParams} from "react-router-dom";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const ImgItem = ({ data, reload }) => {

    const [isdelete, setIsdelete] = useState(false);


    function DeleteInGalery() {

        setIsdelete(true);

        JovanGroup.Article.deletegalerys(data.id).then(res => {
            if (res.data) {
                setIsdelete(false);
                notify(true, "Suppresion l'image effectué");
                reload();
            }
        }).catch(err => {
            setIsdelete(false);
            notify(true, null, "Echec de la suppression de l'image");
            reload();
        });

    };

    return (
        <div className="col-4 col-md-2 mb-4">
            <div className="card border-0">
                <div className="thumbnail">

                    <img width="100%" height="150"
                         src={url + "/uploads/galerys/" + data.image} alt="thumbnail" />

                    <a href="#" className="thumb-cover"></a>
                </div>
                <div className="card-info bg-light d-inline-flex justify-content-center align-items-center align-contents-center py-2">
                    <button onClick={DeleteInGalery}
                            className="btn btn-outline-danger px-2 py-1 btn-sm">


                        {
                            isdelete ?
                                <div className="spinner-border spinner-border-sm"
                                     role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                : <i className="fas fa-trash-alt"></i>
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};
//
// const Copy = ({ data, id }) => {
//
//     const [iscopy, setIscopy] = useState(false);
//
//     function copyToClipboard(e) {
//         setIscopy(true)
//         navigator.clipboard.writeText(url + "/uploads/links/" + data.image);
//
//         setTimeout(() => setIscopy(false),
//             300
//         );
//         notify(true, "Le lien de l'image a été copié avec succès");
//
//     }
//
//     return (
//         <div className="col-12 col-md-3" key={id}>
//             <div className="card border-0">
//                 <div className="thumbnail">
//
//                     <img width="100%" height="107px"
//                          src={url + "/uploads/links/" + data.image} alt="thumbnail" />
//
//                     <a href="#" className="thumb-cover"></a>
//                 </div>
//                 <div className="card-info bg-light d-inline-flex justify-content-center align-items-center align-contents-center p-1">
//                     <button
//                         id="copier"
//                         className="btn btn-outline-danger px-2 py-1 btn-sm" onClick={copyToClipboard}>
//
//
//                         {
//                             iscopy ?
//                                 <div className="spinner-border spinner-border-sm"
//                                      role="status">
//                                     <span className="sr-only">Loading...</span>
//                                 </div>
//                                 : "Copier l'url"
//                         }
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };
//


const UpArticles = () => {

    const {id} = useParams();
    const [NewsDetails, setNewsDetails] = useState({});
    const [cats, setCats] = useState([]);
    const [add, setAdd] = useState(false);
    const [Def, setDef] = useState(true);

    const [Vari, setVari] = useState("");
    const [loadgal, setLoadgal] = useState(false);
    const [links, setLinks] = useState([]);
    const [AddContent, setAddContent] = useState(false);
    const [file, setFile] = useState("");
    const [loader, setLoader] = useState(false);
    let [Category,setCategory] = useState();
    const [content, setContent] = useState("");



    function AddContents() {

        setAddContent(true);
        let formData = new FormData();
        formData.append("content", content);
        JovanGroup.Article.addContent(id, formData).then(res => {
            if (res.data) {
                setAddContent(false);
                notify(true, "Ajout du contenu effectué");
                // getArticle();
            }
        }).catch(err => {
            notify(true, null, "Echec de l'ajout du contenu l'actualité");
        });
    }

    function handleUpload(event) {
        setFile(event.target.files[0]);

    }

    // function AddLinks(e) {
    //
    //     e.preventDefault();
    //
    //     let formData = new FormData(e.target);
    //     JovanGroup.Links.add(formData).then(res => {
    //         if (res.data) {
    //             JovanGroup.Links.get().then(res => {
    //                 if (res.data) {
    //                     setLinks(res.data);
    //                     notify(true, "Ajout du lien effectué")
    //                 }
    //             })
    //         }
    //     })
    // }

    function AddInGalery(e) {

        e.preventDefault();
        setLoadgal(true);
        let formData = new FormData(e.target);
        formData.append("news", NewsDetails.id);

        JovanGroup.Article.gals(formData).then(res => {
            if (res.data) {
                setLoadgal(false);
                notify(true, "Ajout de l'image effectué");
                getArticle();
                setDef(false);
                // setEditContent(false)
            }
        }).catch(err => {
            setLoadgal(false);
            notify(true, null, "Echec de l'ajout de l'image");
        });
    };

    function getArticle() {

        setLoader(true)
        JovanGroup.Article.detail(id).then(res => {
            if (res.data) {
                getCats();
                setLoader(false);
                setNewsDetails(res.data);

            }
        }).catch(err => {

        })
    };

    function getCats() {


        JovanGroup.Category.get().then(res => {
            if (res.data) {

                setCats(res.data);
            }
        }).catch(err => {

        })
    };

    function UpdateHeader(event) {

        setAdd(true);
        event.preventDefault();

        let cat = [Category];
        let formData = new FormData(event.target);
        formData.append("categorys", JSON.stringify(cat));

        JovanGroup.Article.update(id,formData).then(res => {

            if (res.data) {

                setAdd(false);
                notify(true, "Modification de l'entếte effectué");
                getArticle();
            }
        }).catch(err => {

            setAdd(false);
            notify(true, null, "Echec de la Modification de l'entếte");

        });
    }

    function Reload() {
        getArticle();
    }
    // function AddContents() {
    //
    //     setAddContent(true);
    //     let formData = new FormData();
    //     formData.append("content", content);
    //     JovanGroup.Article.addContent(id, formData).then(res => {
    //         if (res.data) {
    //             setAddContent(false);
    //             notify(true, "Ajout du contenu effectué");
    //             // getArticle();
    //         }
    //     }).catch(err => {
    //         notify(true, null, "Echec de l'ajout du contenu l'actualité");
    //     });
    // }

    function UserInterface (i){

        if (i === "galerie") {
            let gal = document.getElementsByTagName("ck-toolbar");
            gal.className +="d-none";
            return (
                <>

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <p className="h5 fw-bold"> Galerie </p>
                        <button className="btn btn-sm btn-outline-primary "
                                data-bs-target="#modalAddInGal" data-bs-toggle="modal" >
                            <i className="fas fa-plus " />
                            Ajouter une photo
                        </button>
                    </div>


                    <div className="col-12 col-md-12">
                        <p>images en ligne : { "galerys" in NewsDetails && NewsDetails.galerys.length !== 0 ? NewsDetails.galerys.length : "0"} </p>

                        <div className="row">
                            {
                                "galerys" in NewsDetails && NewsDetails.galerys.length !== 0 &&
                                NewsDetails.galerys.map((gal, index) => {
                                    return (
                                        <ImgItem data={gal} reload={Reload} key={index} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            );
        }
        else if (i === "contenu"){
            return (
                <>

                    <CKEditor
                        editor={ ClassicEditor }
                        data={NewsDetails.content ? NewsDetails.content : ""}
                        onReady={ editor => {
                            // You can store the "editor" and use when it's needed.
                            console.log( 'Editor is ready to use!', editor );
                            // editor.ui.view.editable.element.parentElement.insertBefore(
                            //     editor.ui.view.toolbar.element,
                            //     editor.ui.view.editable.element
                            // );

                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setContent(editor.getData())
                            console.log( {  data } );
                        } }
                    />

                    <div className="text-end">
                        <button className="btn  btn-outline-primary btn-log mt-3" onClick={AddContents}>
                            {
                                AddContent ?
                                    <div className="spinner-border " role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>:
                                    <span>
                                        <i className="fas fa-plus me-2" />
                                        Ajouter le contenu
                                    </span>
                            }
                        </button>
                    </div>
                </>
            );
        }
        else {
            let gal = document.getElementsByClassName("ck-toolbar");
            gal.className +="d-none";

            return (<>
                <div>
                    <p className="h5 fw-bold"> Titre : {NewsDetails.title }</p>
                    <p className="h5"> <span className="fw-bold">Auteur</span> : {NewsDetails.ecrivain }</p>
                    <p className="h5"> <span className="fw-bold">Categories</span> :</p>
                    <ul className="list-inline-item ps-2">
                        {"categorys" in NewsDetails &&
                        NewsDetails.categorys.length !== 0 &&
                        NewsDetails.categorys.map(el => {
                            return  <li>{el.name}</li>
                        }) }
                    </ul>
                </div>

                <div className="row mt-4">
                    <div className="col-3">
                        <p className="fw-bold mb-3">Photo de couverture : </p>
                        <img src={url + "/uploads/news/" + NewsDetails.image} className="img-fluid" alt="Couverture de l'article"/>
                    </div>
                    <div className="col-9">
                        <p className="fw-bold mb-3">Description (introduction): </p>
                        <p>{NewsDetails.description}</p>
                    </div>
                </div>
            </>);
        }

    }

    useEffect(() => {
        getArticle();
    }, []);

    return (
        <>
            <Navbar />

            <div className="container-fluid">
                <div className="row">

                    <Sidebar/>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Gestion des articles / Edition</h1>

                            <div className="btn-group">

                                {
                                    Def ?
                                        <button className="btn  btn-outline-primary "
                                                data-bs-target="#modalUpdateHeader" data-bs-toggle="modal">
                                            <i className="fas fa-edit me-2" />
                                            Modifier l'entête
                                        </button> :
                                        <button className="btn  btn-outline-primary "
                                                onClick={()=>{setDef(true); setVari("")}}
                                                >
                                            <i className="fas fa-edit me-2" />
                                            Entête
                                        </button>
                                }
                                <button className="btn  btn-outline-primary" onClick={()=>{setDef(false);setVari("contenu") }}>
                                    <i className="fas fa-file-edit ms-2" />
                                    Editer le contenu
                                </button>
                                <button className="btn  btn-outline-primary" onClick={()=>{setDef(false);setVari("galerie")}}>
                                    <i className="fas fa-galery ms-2" />
                                    Editer la galerie
                                </button>

                            </div>

                        </div>

                        {
                            loader ?
                                <div className="text-center py-5">
                                    <div className="spinner-grow " role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> :
                                <>
                                    {UserInterface(Vari)}
                                </>


                        }

                    </main>

                </div>

                <div className="modal" id="modalUpdateHeader" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered " role="document">

                        <div className="modal-content px-4">
                            <div className="modal-header">
                                <h5 className="modal-title">Modification de l'entete </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>

                            <form onSubmit={UpdateHeader}>

                                <div className="modal-body">

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="title">Titre de l'actualité</label>
                                        <input type="text" className="form-control" name="title" placeholder="titre ..." defaultValue={NewsDetails.title} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="category">Category de l'actualité</label>
                                        <select className="form-select"  onChange={(e)=>setCategory(e.target.value)}  >
                                            <option defaultValue="">Deroulez la selection</option>
                                            {
                                                cats && cats.map((el, i)=>{
                                                    return <option value={el.id} key={i}>{el.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>


                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="ecrivain">Auteur de l'actualité</label>
                                        <input type="text" className="form-control" name="ecrivain" placeholder="Auteur ..." defaultValue={NewsDetails.ecrivain} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="formFile" className="form-label">{file.name ? file.name : "Ajouter une image de couverture"}</label>
                                        <input className="form-control" name="imageFile" id="imageFile" type="file"   />
                                    </div>


                                    <div className="mb-3">
                                        <label htmlFor="description">Description de l'actualité</label>
                                        <textarea className="form-control" name="description" rows="3" defaultValue={NewsDetails.description} />
                                    </div>


                                </div>


                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary btn-log" data-bs-dismiss="modal">Annuler</button>
                                    <button type="submit" className="btn btn-primary btn-log">
                                        {
                                            add ? <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : "Ajouter l'article"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal" id="modalAddInGal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered " role="document">

                        <div className="modal-content px-4">
                            <div className="modal-header">
                                <h5 className="modal-title">Ajouter une image dans la galerie </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>

                            <form onSubmit={AddInGalery}>

                                <div className="modal-body">
                                    <div className="modal-body pl-0 pr-3">

                                        <div className="form-group ">
                                            <div className="custom-file">

                                                <label htmlFor="formFile" className="form-label"> {file.name ? file.name : "Ajouter une image dans la galerie"}</label>
                                                <input className="form-control" onChange={handleUpload} name="imageFile" id="imageFile" type="file"   />

                                                {/*<input type="file" className="custom-file-input" onChange={handleUpload} name="imageFile" id="imageFile"*/}
                                                {/*       lang="fr" />*/}

                                                {/*<label className="custom-file-label" htmlFor="imageFile">*/}
                                                {/*    {file.name ? file.name : "Ajouter une image dans la galerie"}*/}
                                                {/*</label>*/}

                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary btn-log" data-bs-dismiss="modal">Annuler</button>
                                    <button type="submit" className="btn btn-primary btn-log">
                                        {
                                            loadgal ? <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : "Ajouter l'image"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>


        </>
    );
};

export default UpArticles;