import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();


export function notify(bool, success, error) {
    bool === true ? toast.success(success, {autoClose: 3000}) : toast.error(error, {autoClose: 3000});
}

export const url = "https://serveur.jovan-group.com";
// export const url = "http://localhost:8000";

