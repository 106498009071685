import React, {useState,useEffect} from 'react';
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar";
import JovanGroup from "../../constants/BlogApi";
import {notify} from "../../constants/Constants";
import Article from "../../components/Article";

const Articles = () => {

    const [news, setNews] = useState([]);
    const [cats, setCats] = useState([]);
    const [add, setAdd] = useState(false);
    const [file, setFile] = useState("");
    const [loader, setLoader] = useState(false);
    let [Category,setCategory] = useState();

    // function handleUpload(event) {
    //     setFile(event.target.files[0]);
    //
    // }

    function getArticle() {

        setLoader(true)
        JovanGroup.Article.get().then(res => {
            if (res.data) {
                getCats();
                setLoader(false);
                setNews(res.data);
            }
        }).catch(err => {

        })
    };

    function getCats() {


        JovanGroup.Category.get().then(res => {
            if (res.data) {

                setCats(res.data);
            }
        }).catch(err => {

        })
    };

    function addArticle(event) {

        setAdd(true);
        event.preventDefault();

        let cat = [Category];
        let formData = new FormData(event.target);
        formData.append("categorys", JSON.stringify(cat));

        JovanGroup.Article.add(formData).then(res => {

            if (res.data) {

                setAdd(false);
                notify(true, "Ajout de l'actualité effectué");
                getArticle();
            }
        }).catch(err => {

            setAdd(false);
            notify(true, null, "Echec de l'ajout de l'actualité");

        });
    }

    function Reload() {
        getArticle();
    }
    useEffect(() => {
        getArticle();
    }, []);

    return (
        <>
            <Navbar />

            <div className="container-fluid">
                <div className="row">

                    <Sidebar/>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Gestion des articles</h1>

                            <button className="btn btn-sm btn-outline-primary " data-bs-target="#modalAdd" data-bs-toggle="modal">
                                <i className="fas fa-user-plus me-2" />
                                Ajouter un article
                            </button>

                        </div>

                        {
                            loader ?
                                <div className="text-center w-100">
                                    <div className="spinner-grow jo" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> :
                                <>
                                <div className="table-responsive">
                                    <table className="table align-middle">
                                        <thead>
                                        <tr>
                                            <th scope="col">Couverture </th>
                                            <th scope="col">Titre</th>
                                            <th scope="col">Introduction</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            news && news.length !== 0 ?
                                            news.map((actualite, index) => {
                                                return (
                                                    <Article data={actualite} key={index} reload={Reload} />);
                                            })
                                            :
                                            <div className="col-12 col-md-12">
                                                <p>Aucun article n'a été créé.</p>
                                            </div>
                                        }
                                        </tbody>
                                    </table>
                                </div>



                                </>

                        }

                    </main>

                </div>

                <div className="modal" id="modalAdd" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered " role="document">

                        <div className="modal-content px-4">
                            <div className="modal-header">
                                <h5 className="modal-title">Ajouter un sarticle </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>

                            <form onSubmit={addArticle}>

                                <div className="modal-body">

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="title">Titre de l'actualité</label>
                                        <input type="text" className="form-control" name="title" placeholder="titre ..." />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="category">Category de l'actualité</label>
                                        <select className="form-select"  onChange={(e)=>setCategory(e.target.value)}  >
                                            <option defaultValue="">Deroulez la selection</option>
                                            {
                                                cats && cats.map((el, i)=>{
                                                    return <option value={el.id} key={i}>{el.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>


                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="ecrivain">Auteur de l'actualité</label>
                                        <input type="text" className="form-control" name="ecrivain" placeholder="Auteur ..." />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="formFile" className="form-label">{file.name ? file.name : "Ajouter une image de couverture"}</label>
                                        <input className="form-control" name="imageFile" id="imageFile" type="file"   />
                                    </div>


                                    <div className="mb-3">
                                        <label htmlFor="description">Description de l'actualité (introduction)</label>
                                        <textarea className="form-control" name="description" rows="3" />
                                    </div>


                                </div>


                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary btn-log" data-dismiss="modal">Annuler</button>
                                    <button type="submit" className="btn btn-primary btn-log">
                                        {
                                            add ? <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : "Ajouter l'article"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>


        </>
    );
};

export default Articles;