import React, { useState } from 'react';
import {notify, url} from "../../constants/Constants";
import JovanGroup from "../../constants/BlogApi";
import defaultImage from "../../assets/logo.png"
import {Link} from "react-router-dom";

const Article = ({ data, key, reload }) => {

    const [loader, setLoader] = useState(false);

    function deleteArticle(id) {

        setLoader(true);
        JovanGroup.Article.deletenews(id).then(res => {
            if (res.data) {
                notify(true, "Suppression effectuée ")
                reload();
                setLoader(false);
            }
        }).catch(err => {
            notify(false, "Suppression effectuée ");
            setLoader(false);
        });

    }

    return (
        <tr>
            <td>
                <img width="100" className="img-fluid"
                     src={data.image ? url + "/uploads/news/" + data.image : defaultImage}
                     alt="couverture de l'article" />
            </td>
            <td>
                {data.title}
            </td>

            <td>
                {data.description}
            </td>
            <td className="d-inline-flex ">
                <button className="btn btn-outline-danger me-3 " onClick={() => deleteArticle(data.id)}>
                    {
                        loader ?
                            <div className="spinner-border spinner-border-sm text-danger" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <i className="fas fa-trash-alt"/>
                    }
                </button>

                <Link className="btn btn-outline-primary ml-2" to={"/update/" + data.id}>
                    <i className="fas fa-pen"/>
                </Link>
            </td>
        </tr>


    );
}

export default Article;
